html, body, #root {
  min-height:100vh;
}

body {
  margin: 0;
  padding: 0;
  min-height:100vh;
  font-family: sans-serif;
  position: relative;
  font-family: 'Roboto', sans-serif;
  
}

#root {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.mainContainer {
  flex:1 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.collapseCell {
  width: 1%;
  white-space: nowrap;
}

main {
  flex: 1 1;
  z-index: 1;
  padding-top:56px;
  padding-bottom:40px;
}

.terms-text {
  height:200px;
}

.auth-form {
  max-width: 400px;
}

.profile-form {
  max-width: 600px;
}

button:active,
button:focus {
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

td {
  width: auto;
}

td.min {
  width: 1%;
  white-space: nowrap;
}

.offset-top-2 {
  margin-top:-4px;
}

.custom-notification {
  display:inline-block;
  padding:8px 20px;
  border-radius:5px;
  position: fixed;
  bottom:40px;
  z-index:10000;
  left:50%;
  transform: translate(-50%, 0%);
}

.custom-popup {
  z-index:11000;
}

.text-hover-underline:hover {
  text-decoration: underline!important;
  cursor: pointer;
}

.text-underline {
  text-decoration: underline!important;
  cursor: pointer;
}

.btn-circle.btn-sm {
  width: 30px;
  height: 30px;
  padding: 4px 0px;
  border-radius: 15px;
  font-size: 12px;
  text-align: center;
  border: none;
  background-color: #fff;
  color: #000;
}

.btn-circle.btn-sm :active
.btn-circle.btn-sm :hover
.btn-circle.btn-sm :focus {
  outline: none;
}
.text-black {
  color: #000;
}

.numberplate {
  width:150px;
  padding: 10px;
  text-align:center;
}

.dropdown-item.disabled {
  text-decoration: line-through;
}
